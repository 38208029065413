import { NimmersoOfflineStorage} from '../../../utils/nimmerso-offline-storage'

export default {
  namespaced: true,
  state: {
    items:  NimmersoOfflineStorage.get('searchfilters') || [],
  },
  mutations: {
    setsearchfilters: (state,searchfilters) => {
      state.items=searchfilters
      NimmersoOfflineStorage.set('searchfilters',searchfilters,365*24*60*60);
      //window.console.log('searchfilters',searchfilters);
    },
    removeSearchfilters: (state) => {
      state.items=[]
      NimmersoOfflineStorage.set('searchfilters', null);
    },
  },
  actions: {
    setsearchfilters({ commit },searchfilters) {
        commit('setsearchfilters',searchfilters)
    },
    removeSearchfilters({ commit }) {
      commit('removeSearchfilters')
    },
  },
  getters: {
    items (state) {
      return state.items
      //return NimmersoOfflineStorage.get('searchfilters');
    }
  }
}
