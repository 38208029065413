import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import { NimmersoOfflineStorage} from '@/utils/nimmerso-offline-storage'

const getItems = ({ commit }, page = 'feed_items') => {
  commit(types.TOGGLE_LOADING)
  if (!navigator.onLine){
    const data=NimmersoOfflineStorage.get(page);
    if (data){
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ITEMS, data['hydra:member'])
      commit(types.SET_VIEW, data['hydra:view'])
      commit(types.SET_TOTALITEMS, data['hydra:totalItems'])
    }else{
      commit(types.TOGGLE_LOADING)
      commit(types.SET_ERROR, 'Unable to retrieve data. Device is offline')
    }

  }else{
    fetch(page)
      .then(response => response.json())
      .then((data) => {
        NimmersoOfflineStorage.set(page,data);
        commit(types.TOGGLE_LOADING)
        commit(types.SET_ITEMS, data['hydra:member'])
        commit(types.SET_VIEW, data['hydra:view'])
        commit(types.SET_TOTALITEMS, data['hydra:totalItems'])
      })
      .catch((e) => {
        commit(types.TOGGLE_LOADING)
        commit(types.SET_ERROR, e.message)
      })
  }

}

export default getItems
