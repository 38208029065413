import { NimmersoOfflineStorage} from '../../../utils/nimmerso-offline-storage'

export default {
  namespaced: true,
  state: {
    items:  NimmersoOfflineStorage.get('settings') || {},
  },
  mutations: {
    setsettings: (state,settings) => {
      state.items=Object.assign({},state.items,settings)
      NimmersoOfflineStorage.set('settings',Object.assign({},state.items,settings),365*24*60*60);
      //window.console.log('settings',Object.assign({},state.items,settings));
    }
  },
  actions: {
    setsettings({ commit },settings) {
        commit('setsettings',settings)
    },
    getsettings(state){
      //window.console.log('getsettings',this.state.items)
      return state.items
    }
  },
  getters: {
    items (state) {
      return state.items
      //return NimmersoOfflineStorage.get('settings');
    }
  }
}
