import { VueOfflineStorage } from 'vue-offline'



export const NimmersoOfflineStorage = {
  keys: VueOfflineStorage.keys,
  set (key, value, lifeTimeInSeconds=60*60*24) {
    const currentTime=Math.floor(new Date().getTime()/1000);
    if (value==null){
      if (typeof window !== 'undefined' ) localStorage.removeItem(key);
      return;
    }
    VueOfflineStorage.set(key,{expires: currentTime+lifeTimeInSeconds, value: value})
  },
  get (key) {
    const currentTime=Math.floor(new Date().getTime()/1000);
    const data=VueOfflineStorage.get(key);
    if (data==null) return null;
    if (typeof data.expires=='undefined' || data.expires<currentTime) {
      if (typeof window !== 'undefined' ) localStorage.removeItem(key);
      return null;
    }
    return data.value
  },
  clearExpired(){
    const currentTime=Math.floor(new Date().getTime()/1000);
    const keys=JSON.parse(VueOfflineStorage.keys);
    window.console.log(typeof keys, keys);
    if (typeof keys!=='object' || keys==null) return;
    let nkeys=[];
    keys.forEach((key)=>{
      if (key!=='VueOfflineStorageKeys') {
        //window.console.log(key);
        const data = VueOfflineStorage.get(key);
        if (data == null || typeof data.expires=='undefined' || data.expires < currentTime) {
          window.console.log('REMOVED:',key);
          if (typeof window !== 'undefined') {
            localStorage.removeItem(key);
            VueOfflineStorage.set()
          }
        }else {
          nkeys.push(key)
        }
      }
    })
    localStorage.setItem('VueOfflineStorageKeys', JSON.stringify(nkeys));
  }
}
