
import { NimmersoOfflineStorage} from '../../../../utils/nimmerso-offline-storage'
import VueJwtDecode from 'vue-jwt-decode'

const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

export default {
  namespaced: true,
  state: {
    token:  NimmersoOfflineStorage.get(TOKEN_KEY) || null,
    token_expires:  NimmersoOfflineStorage.get(TOKEN_KEY+'_expires') || 0,
    refresh_token: NimmersoOfflineStorage.get(REFRESH_TOKEN_KEY) || null,
    token_data: NimmersoOfflineStorage.get(TOKEN_KEY) ? VueJwtDecode.decode(NimmersoOfflineStorage.get(TOKEN_KEY) ) : null
  },
  mutations: {
    settoken: (state,token) => {
      const data=VueJwtDecode.decode(token)
      const currentTime=Math.floor(new Date().getTime()/1000);
      const deltatime=data.iat-currentTime;
      NimmersoOfflineStorage.set(TOKEN_KEY,token, data.exp-data.iat);
      Object.assign(state, {
        token_data: VueJwtDecode.decode(token),
        token_expires: data.exp-deltatime,
        token: token
      });
      NimmersoOfflineStorage.set(TOKEN_KEY+'_expires', data.exp-deltatime);

    },
    removetoken: (state) => {
      Object.assign(state, {
        token: null,
        token_expires: null
      });
      NimmersoOfflineStorage.set(TOKEN_KEY, null);
      NimmersoOfflineStorage.set(TOKEN_KEY+'_expires', null);
    },
    setrefreshtoken:(state,refresh_token) => {
      Object.assign(state, {
        refresh_token: refresh_token
      });
      NimmersoOfflineStorage.set(REFRESH_TOKEN_KEY,refresh_token, 365*24*60*60);
    },
    removerefreshtoken: (state) => {
      Object.assign(state, {
        refresh_token: null
      });
      NimmersoOfflineStorage.set(REFRESH_TOKEN_KEY, null);
    },
  },
  actions: {
    settoken({ commit },token) {
      commit('settoken',token)
    },
    setrefreshtoken({ commit },refresh_token) {
      commit('setrefreshtoken',refresh_token)
    },
    gettoken(state){
      return state.token
    },
    gettokenexpiretime(state){
      return state.token_expires;
    },
    gettokendata(state){
      return state.token_data;
    },
    removetoken({ commit }) {
      commit('removetoken')
    },
    removerefreshtoken({ commit }) {
      commit('removerefreshtoken')
    },

  },
  getters: {
    token (state) {
      return state.token
    },
    refresh_token (state) {
      return state.refresh_token
    },
    token_expires (state) {
      return state.token_expires
    },
    token_data (state) {
      return state.token_data
    }


  }
}
