import SubmissionError from '../error/SubmissionError'
import { ENTRYPOINT } from '@/config/entrypoint';
import store from '../store/modules/user/index'
import Vuex from 'vuex'
//import { VueOfflineStorage } from 'vue-offline'

const MIME_TYPE = 'application/ld+json'
//const TOKEN_KEY = 'beaccess_token'

export default function (id, options = {}) {
  if (typeof options.headers === 'undefined') Object.assign(options, { headers: new Headers() })
  if (options.headers.get('Accept') === null) options.headers.set('Accept', MIME_TYPE)
  //const token=VueOfflineStorage.get(TOKEN_KEY);
  //const token_expires=store.modules.token.state.token_expires;
  //const currentTime = Math.floor(new Date().getTime() / 1000)
  //window.console.log('fetch',token_expires,currentTime,token,store.modules.token.state.token);
  if (store.modules.token.state.token) options.headers.set('Authorization',`Bearer ${store.modules.token.state.token}`);
  //if (token) options.headers.set('Authorization',`Bearer ${token}`);
  if (options.body !== undefined && !(options.body instanceof FormData) && options.headers.get('Content-Type') === null) {
    options.headers.set('Content-Type', MIME_TYPE)
  }

  const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/')
  return fetch(new URL(id, entryPoint), options).then((response) => {
    if (response.ok) return response
    return response
      .json()
      .then((json) => {
        const error = json['hydra:description'] ? json['hydra:description'] : response.statusText
        if (response.status===401 ){ //unautorized
          //store.modules.token.actions.removetoken();
          //store.dispatch('user/token/removetoken');
          //store.modules.token.dispatch('removetoken');
          //store.modules.token.actions.dispatch('removetoken');
          //store.dispatch('token/removetoken');
          //store.token.dispatch('removetoken');
          //store.dispatch('token/removetoken');
          //store.dispatch('removetoken');
          //console.log(store.dispatch);
          //Vuex.Store.dispatch('user/token/removetoken')
          //store.modules.token.actions.dispatch('removetoken')
          //Vue.$store.dispatch('user/token/removetoken')
          //TokenService.removeToken();
          let vstore= new Vuex.Store(store);
          vstore.dispatch('token/removetoken');
          //window.location.reload();
        }
        if (!json.violations) throw Error(error)


        const errors = { _error: error }
        json.violations.map(violation =>
          Object.assign(errors, { [violation.propertyPath]: violation.message }))

        throw new SubmissionError(errors)
      })
  })
}
